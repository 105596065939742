.query-form {
    margin: 0 100px;
    background: white;
    padding: 10px 30px;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    
    i {
        color: $blue;
        cursor: pointer;
        padding: 0 10px 0;
    }
    
    input {
        outline: 0;
        width: 100%;
        text-overflow: ellipsis;
    }
}
