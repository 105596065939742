.results {
    margin: 20px 100px 0;
    padding: 0;
 
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    
    h2 {
        align-self: flex-start;
        color: $blue;
    }

    .document {
        background: white;
        border-radius: 0.25rem;
        margin-top: 30px;
        width: 100%;

        .documentNumber {
            display: inline-block;
            width: 2em;
            text-align: right;
            margin-right: 1em;
        }

        .filename {
            border-radius: 0.25rem 0.25rem 0 0;
            background-color: wheat;
            color: $blue;
            margin: 0;
            padding: 5px 20px;
            text-align: left;
            font-weight: 700;
        }

        .collapse-toggle {
            float: right;
            cursor: default;
        }

        .hits {
            margin-left: 20px;

            &.collapsed {
                height: 10px;

                .hit {
                    display: none;
                }
            }

            .hit {
                background-color: rgb(247, 239, 224);
                text-align: left;
                padding: 5px;
                margin: 5px 0;
    
                em {
                    background-color: wheat;
                }
            }
        }

    }

    .navigation {
        margin-top: 30px;

        button {
            margin: 0 10px;
            width: 10em;
            background-color: lighten($blue, 10%);
        }
    }
}
