.login-form {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    
    .error {
        text-align: left;
        color: $brand-danger;
        width: 15em;
        height: 1em;
        padding: 10px;
        font-size: 120%;
        font-weight: bolder;
    }

    input {
        outline: 0;
        width: 15em;
        background: white;
        padding: 10px;
        margin: 10px 0;
        border-radius: 0.25rem;    
    }

    button {
        margin-top: 20px;
    }
}
